import React from 'react'
import T from 'prop-types'
import { graphql } from 'gatsby'

import Article from 'components/Article'
import Layout from 'components/Layout'
import CommonMetatags from 'components/metatags/CommonMetatags'
import ArticleMetatags from 'components/metatags/ArticleMetatags'

function PostTemplate({ data }) {
  return (
    <Layout logo={data.logo} reducedHeader>
      <CommonMetatags />
      <ArticleMetatags article={data.post} />
      <Article data={data} />
    </Layout>
  )
}

PostTemplate.propTypes = {
  data: T.shape({
    logo: T.shape({}),
    post: T.shape({
      title: T.string,
    }),
  }),
}

export default PostTemplate

export const query = graphql`
  query BlogPostById(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    logo: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, placeholder: BLURRED)
      }
    }

    followImg: file(relativePath: { eq: "apple-worm.png" }) {
      childImageSharp {
        gatsbyImageData(width: 150, placeholder: BLURRED)
      }
    }

    post: wpPost(id: { eq: $id }) {
      id
      uri
      slug
      excerpt
      content
      title
      date
      modified
      tags {
        nodes {
          slug
          name
        }
      }
      featuredImage {
        node {
          altText
          sourceUrl
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1000, placeholder: BLURRED)
            }
          }
        }
      }
    }

    previousPost: wpPost(id: { eq: $previousPostId }) {
      uri
      title
    }

    nextPost: wpPost(id: { eq: $nextPostId }) {
      uri
      title
    }
  }
`
